.vcLocation {
  display: flex;
  align-items: center;
  gap: 8px;

  .in {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}
