@use 'const';
@use 'responsive';
@use 'theme';
@use 'mixins';
@use 'designSystem';

.staffCard {
  @include theme.apply(background-color, neutral-white);
  @include theme.apply(color, foreground-default);
  @include designSystem.effectShadowS;

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  z-index: 1;

  @include responsive.ltLargeDesktop {
    min-height: unset;
  }

  img,
  svg {
    display: flex;
    object-fit: cover;
    width: 100%;
  }

  .imageContainer {
    button {
      @include mixins.cleanButton;
    }

    .image {
      aspect-ratio: 4 / 3;
    }
  }

  .content {
    padding: 20px 24px;
    display: flex;
    position: relative;
    flex-direction: column;
    gap: const.$margin * 0.25;
    height: 100%;

    @include responsive.ltLargeDesktop {
      padding: 24px;
    }

    @include responsive.ltDesktop {
      padding: 36px;
    }

    @include responsive.ltMedium {
      padding: 24px;
    }

    @include responsive.mobile {
      padding: 36px;
    }

    @include responsive.smallPhone {
      padding: 24px;
    }

    h2 {
      @include designSystem.textStyleHeadingSmall18;
    }

    .groups {
      display: flex;
      flex-direction: row;
      gap: 8px;

      .title {
        @include theme.apply(color, brand-default);
        @include designSystem.textStyleHeadingSmall14;

        padding: 3px 12px;
        border-radius: const.$margin * 1.5;
        border: solid 2px;

        @include theme.apply(border-color, brand-default);
      }
    }

    p {
      @include theme.apply(color, foreground-default);
      @include designSystem.textStyleBodySmall16;
    }

    .contact {
      @include theme.apply(color, foreground-default);
      @include designSystem.textStyleBodySmall16;

      text-decoration: none;
      display: grid;
      position: relative;
      grid-template-columns: 20px 1fr;
      gap: 3px;
      align-items: center;
      width: max-content;
      z-index: 2;
      max-width: 100%;

      svg {
        height: 18px;
      }

      span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .link {
    @include mixins.cleanButton;

    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &:focus {
      outline: none;
    }
  }
}
