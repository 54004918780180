@use 'responsive';
@use 'designSystem';
@use 'theme';

.contentFaq {
  @include theme.apply(color, foreground-default);

  display: flex;
  flex-direction: column;
  row-gap: 48px;
  padding: 0 128px;

  @include responsive.mobile {
    padding: 48px 0;
  }

  .heading {
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;

      @include designSystem.textStyleHeadingLarge36;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}
