@use 'const';
@use 'designSystem';
@use 'responsive';
@use 'theme';
@use 'mixins';

.promotionTile {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 48px 40px;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  min-height: auto;
  min-width: 0;
  position: relative;
  gap: 36px;

  @include theme.apply(color, brand-on-brand);
  @include theme.apply(background-color, foreground-default);
  @include mixins.svgSize('6XL');

  @include responsive.ltDesktop {
    padding: 24px;
  }

  .imageIcon {
    overflow: hidden;
    max-height: 72px;
    flex-shrink: 0;

    img {
      display: block;
      object-fit: contain;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    min-width: 0;
    gap: 24px;
    width: 100%;

    .heading {
      display: -webkit-box; //stylelint-disable-line
      -webkit-line-clamp: 3; //stylelint-disable-line
      -webkit-box-orient: vertical; //stylelint-disable-line
      overflow: hidden;

      @include designSystem.textStyleHeadingLarge36;
    }

    .body {
      display: -webkit-box; //stylelint-disable-line
      -webkit-line-clamp: 5; //stylelint-disable-line
      -webkit-box-orient: vertical; //stylelint-disable-line
      overflow: hidden;

      @include designSystem.textStyleBodyLarge24;
    }
  }

  .buttonStyle {
    white-space: nowrap;
    width: min-content;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;

    @include mixins.svgSize('L');
  }

  // PromotionTileVariants
  &.brandPrimary,
  &.brandSecondary {
    @include theme.apply(background-color, brand-default);
  }

  &.foregroundPrimary,
  &.foregroundSecondary,
  &.foregroundTertiary {
    @include theme.apply(background-color, foreground-default);
  }

  &.actionPrimary,
  &.actionSecondary {
    @include theme.apply(background-color, action-default);
  }

  &.actionLighten {
    @include theme.apply(background-color, action-lighten);
  }

  &.actionLightPrimary,
  &.actionLightSecondary {
    @include theme.apply(background-color, action-light-default);
    @include theme.apply(color, action-light-on-action-light);
  }

  &.smallMobile {
    @include responsive.ltDesktop {
      padding: 24px;
      gap: 30px;

      .heading {
        @include designSystem.textStyleHeadingMedium21;
        -webkit-line-clamp: 2; //stylelint-disable-line
      }

      .body {
        @include designSystem.textStyleBodySmall18;
      }

      .text {
        gap: 8px;
      }

      .buttonStyle {
        @include designSystem.textStyleHeadingSmall16;

        padding: 24px 12px;
        width: 100%;
        height: const.$buttonheightSmall;
      }

      .imageIcon {
        max-height: 48px;

        @include mixins.svgSize('3XL');
      }
    }
  }
}

[data-site='carplace'] {
  .promotionTile .text .heading {
    /* stylelint-disable-next-line declaration-no-important */
    font-family: MuseoModerno, sans-serif !important;
    font-weight: bold;
  }

  .promotionTile {
    background: var(--colour-brand-default-or-gradient);
  }
}
