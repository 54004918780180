@use 'const';
@use 'responsive';
@use 'theme';
@use 'designSystem';

$pricipalPopupWidth: 140px;
$pricipalPopupHeight: 100px;

.chart {
  margin: 0 auto auto 0;

  @include responsive.ltLargeDesktop {
    margin: 0;
    width: 100%;
  }

  @include responsive.ltMedium {
    display: none;
  }

  .totalInterest {
    display: inherit;
  }

  .chartContainer {
    display: block;
    position: relative;
    margin: 92px 48px 60px 96px;
    width: 570px;
    height: 475px;
    border-left: 3px solid;
    border-bottom: 3px solid;

    @include theme.apply(border-color, foreground-default);

    @include responsive.ltXlDesktop {
      margin-left: 80px;
    }

    @include responsive.ltLargeDesktop {
      margin-right: 24px;
      width: 500px;
    }

    @include responsive.ltDesktop {
      margin: 0 96px 48px;
      width: calc(100% - 192px);
      height: 600px;
    }

    .year {
      position: absolute;
      bottom: -50px;
      margin-left: -30px;
      width: 60px;

      @include designSystem.textStyleHeadingSmall16;
      @include theme.apply(color, foreground-default);
    }

    .yearDivider {
      position: absolute;
      top: 0;
      bottom: -(const.$margin);
      width: 1px;
      z-index: 1;

      @include theme.apply(background-color, foreground-muted);

      @include responsive.ltLargeDesktop {
        bottom: 0;
      }
    }

    .amount {
      position: absolute;
      left: -132px;
      margin-bottom: -10px;
      width: 100px;
      text-align: right;

      @include designSystem.textStyleHeadingSmall16;
      @include theme.apply(color, foreground-default);
    }

    .amountDivider {
      @include theme.apply(background-color, foreground-muted);

      position: absolute;
      right: 0;
      left: -24px;
      height: 1px;
      z-index: 1;

      @include responsive.ltLargeDesktop {
        left: 0;
      }
    }

    .principalPerYear,
    .repaymentPerYear {
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;

      &.repaymentPerYear {
        cursor: pointer;
        z-index: 2;

        &:hover {
          .popup {
            visibility: inherit;
            opacity: 1;
          }
        }
      }

      .popup {
        visibility: hidden;
        opacity: 0;
        box-shadow: 0 0 24px rgb(0 0 0 / 0.16);
        border: 1px solid transparent;
        border-radius: 12px;
        position: relative;
        padding: 12px 12px 8px;
        left: calc(50% - ($pricipalPopupWidth * 0.5));
        top: -70px;
        width: $pricipalPopupWidth;
        min-height: $pricipalPopupHeight;
        z-index: 2;

        @include theme.apply(background-color, neutral-white);

        p {
          @include designSystem.textStyleBodySmall12;
          @include theme.apply(color, foreground-default);

          margin: 0;

          &.bold {
            @include designSystem.textStyleHeadingSmall14;

            margin: 0 0 4px;
          }
        }
      }
    }
  }
}

.principalClipPath,
.repaymentClipPath {
  @include theme.apply(background-color, brand-default);

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.repaymentClipPath {
  @include theme.apply(background-color, neutral-grey-90);

  height: 100%;
  clip-path: polygon(0 0, 0 100%, 100% 100%);
}

.repaymentPoint,
.principalPoint {
  @include theme.apply(background-color, foreground-muted);

  border-radius: 7px;
  position: absolute;
  margin-left: -3px;
  margin-bottom: -3px;
  width: 7px;
  height: 7px;
}

.principalClipPath,
.repaymentClipPath,
.repaymentPoint,
.principalPoint,
.popup {
  transition: all 0.35s ease-out;
}
