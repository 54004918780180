@use 'const';
@use 'responsive';
@use 'designSystem';
@use 'theme';

.entryGrid {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .paragraph {
    h2 {
      @include designSystem.textStyleHeadingLarge42;

      @include responsive.mobile {
        @include designSystem.textStyleHeadingLarge30;
      }

      strong {
        @include theme.apply(color, brand-default);
        @include theme.apply(font-weight, heading-weight);
      }
    }

    h3,
    h4,
    h5,
    h6 {
      @include designSystem.textStyleHeadingLarge36;

      @include responsive.mobile {
        @include designSystem.textStyleHeadingLarge30;
      }

      strong {
        @include theme.apply(color, brand-default);
        @include theme.apply(font-weight, heading-weight);
      }
    }

    p {
      @include designSystem.textStyleBodyLarge24;
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    @include responsive.ltDesktop {
      grid-template-columns: repeat(2, 1fr);
    }

    @include responsive.mobile {
      grid-template-columns: auto;
    }

    &.iconOnly {
      gap: 72px 24px;

      @include responsive.ltDesktop {
        gap: const.$margin * 2;
      }
    }
  }
}
