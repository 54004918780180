@use 'responsive';
@use 'theme';
@use 'designSystem';
@use 'mixins';

.contentTestimonial {
  width: 100%;
  padding: 60px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  @include theme.apply(background, neutral-white);

  @include responsive.mobile {
    padding: 60px 24px 104px;
  }

  .heading {
    width: 100%;
    text-align: center;

    h2,
    h3,
    h4,
    h5,
    h6 {
      @include designSystem.textStyleHeadingLarge36;
    }
  }

  .carouselWrapper {
    position: relative;
  }

  :global {
    .react-multi-carousel-dot > button {
      border: none;
      height: 8px;
      width: 8px;

      @include theme.apply(background, action-disabled);
    }

    .react-multi-carousel-dot--active > button {
      @include theme.apply(background, action-default);
    }

    .react-multi-carousel-dot-list {
      bottom: -40px;

      @include responsive.gtMobile {
        display: none;
      }
    }
  }
}

.slider {
  position: relative;

  .item {
    padding-right: 120px;
    padding-left: 120px;

    @include responsive.mobile {
      padding-right: 24px;
      padding-left: 24px;
    }
  }
}

.buttonGroup {
  position: absolute;
  right: 0;
  left: 0;
  top: 24px;

  > button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    top: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 2;

    @include theme.apply(background-color, action-lightest);
    @include mixins.svgColor(action-default);

    &:nth-child(1) {
      left: 0;
    }

    &:nth-child(2) {
      right: 0;
      transform: rotateY(-180deg);
    }
  }

  @include responsive.mobile {
    display: none;
  }
}
