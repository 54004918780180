@use 'mixins';
@use 'designSystem';
@use 'theme';
@use 'responsive';

.vcTooltip {
  visibility: hidden;
}

.tooltipButton {
  @include mixins.cleanButton;

  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0;

  & > * {
    pointer-events: none;
  }
}
