@use 'const';
@use 'designSystem';
@use 'responsive';
@use 'theme';

.contentCarousel {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-radius: const.$blockBorderRadius;
  overflow: hidden;

  @include theme.apply(background, neutral-white);
  @include theme.apply(color, foreground-default);

  @include responsive.ltDesktop {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    gap: 32px;
  }

  @include responsive.mobile {
    border-radius: 0;
    position: relative;
    margin: 12px -24px;
    padding: 48px 24px;
    width: unset;
  }

  &.verticallyCentredStyle {
    border-radius: 0;

    .image {
      display: flex;
      align-items: center;

      img {
        border-radius: const.$blockBorderRadius;
        height: auto;
        max-height: 100%;
        overflow: hidden;
      }
    }
  }

  &.reserve {
    @include responsive.desktop {
      .image {
        order: 1;
      }

      .content {
        order: 2;
      }
    }
  }

  .image {
    width: 100%;
    height: 100%;

    @include responsive.desktop {
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    @include responsive.ltDesktop {
      padding: 0 64px 64px;
      min-height: unset;
      aspect-ratio: 4 / 3;
      max-width: 100%;

      img {
        border-radius: 30px;
      }
    }

    @include responsive.ltMedium {
      padding: 0 24px 48px;
    }

    @include responsive.mobile {
      padding: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      @include responsive.mobile {
        border-radius: const.$blockBorderRadius;
      }
    }
  }

  .content {
    padding: 64px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    @include responsive.ltLargeDesktop {
      padding: 48px;
    }

    @include responsive.ltDesktop {
      padding: 64px 64px 0;
    }

    @include responsive.ltMedium {
      padding: 48px 24px 0;
    }

    @include responsive.mobile {
      padding: 0;
    }

    .heading {
      h2,
      h3,
      h4,
      h5,
      h6 {
        @include designSystem.textStyleHeadingLarge36;

        strong {
          @include theme.apply(color, brand-default);
          @include theme.apply(font-weight, heading-weight);
        }
      }
    }

    .items {
      display: flex;
      flex-direction: column;
      row-gap: 32px;

      .slot {
        padding: 30px;
        border: none;
        cursor: pointer;
        border-radius: 12px;
        transition: 0.5s all ease;
        display: flex;
        flex-direction: row;
        gap: 0 const.$margin;

        @include theme.apply(background, neutral-white);

        &.active {
          @include theme.apply(background, neutral-grey-95);
        }

        .item {
          @include theme.apply(color, foreground-default);

          text-align: left;
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          width: fit-content;

          h2,
          h3,
          h4,
          h5,
          h6 {
            @include designSystem.textStyleHeadingMedium24;
          }

          p {
            @include designSystem.textStyleBodyMedium22;
          }

          a {
            @include theme.apply(color, brand-default);
          }
        }

        svg {
          width: 40px;
          height: 40px;
          min-width: 40px;
          min-height: 40px;
          max-width: 40px;
          max-height: 40px;

          > *:not([stroke='none']) {
            @include theme.apply(stroke, foreground-default);
          }
        }
      }
    }
  }
}
