@use 'const';
@use 'responsive';
@use 'theme';
@use 'mixins';

.calculator {
  position: relative;
  padding: 0;
  width: 100%;

  > .container {
    @include mixins.containerLayout(0 0 0);

    .content {
      @include theme.apply(background-color, neutral-white);

      border-radius: const.$blockBorderRadius;
      padding: const.$margin * 4 const.$margin * 2.5;
      overflow: hidden;

      @include responsive.ltXlDesktop {
        padding: const.$margin * 3 const.$margin * 2;
      }

      @include responsive.ltLargeDesktop {
        padding: const.$margin * 2.5 const.$margin;
      }

      @include responsive.ltMedium {
        border: none;
        border-radius: const.$blockBorderRadiusMobile;
        padding: const.$margin;
      }
    }
  }
}
