@use 'const';
@use 'responsive';
@use 'theme';
@use 'mixins';
@use 'designSystem';

.dotWrapper {
  width: 0;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in;

  @include mixins.cleanButton;

  &.visible {
    width: 10px;
    height: 10px;
    margin: 0 4px;
  }

  .dot {
    border: none;
    width: 0;
    height: 0;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: all 300ms ease-in;

    @include theme.apply(background-color, neutral-white);

    &:focus {
      outline: none;
    }

    &.dot-fg-subtle {
      @include theme.apply(background-color, foreground-subtle);
    }
  }

  .visible {
    width: 8px;
    height: 8px;
    opacity: 50%;
    visibility: visible;
  }

  .active {
    opacity: 100%;

    &.active-action-default {
      @include theme.apply(background-color, action-default);
    }
  }

  .medium {
    width: 5px;
    height: 5px;
  }

  .small {
    width: 3px;
    height: 3px;
  }
}
