@use 'theme';

.vcDealerComments {
  padding: 16px;
  border: 1px solid;
  border-radius: 4px;
  margin-bottom: 4px;

  & p {
    display: -webkit-box; //stylelint-disable-line
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @include theme.apply('border-color', foreground-subtle);
}
