@use 'const';
@use 'designSystem';
@use 'mixins';
@use 'responsive';
@use 'theme';

.contentStaff {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.notFound {
  @include designSystem.textStyleHeadingMedium21;
  @include theme.apply(color, foreground-default);

  text-align: center;
}
