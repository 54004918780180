@use 'designSystem';
@use 'mixins';
@use 'theme';

.vcLabel {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  @include theme.apply(color, brand-default);
  @include designSystem.textStyleHeadingSmall14;
  @include mixins.svgSize('M');
}
