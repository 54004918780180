@use 'designSystem';
@use 'theme';
@use 'mixins';
@use 'responsive';

.vehicleCarousel {
  position: relative;
  width: 100%;
  height: 100%;

  &[data-navigation-disabled='false'] {
    cursor: pointer;
  }

  .navigation {
    opacity: 0;
    transition: opacity 0.3s;

    &.visible {
      opacity: 1;
    }

    .arrow {
      @include mixins.cleanButton;

      position: absolute;
      top: 50%;
      left: 15px;
      padding: 4px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include theme.apply(background-color, foreground-default);
      @include theme.apply(color, foreground-on-foreground);

      &.right {
        transform: rotate(180deg);
        left: unset;
        right: 15px;
      }

      &:disabled {
        display: none;
      }
    }
  }

  .progressDots {
    position: absolute;
    bottom: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ctaWrapper {
    background-color: rgb(255 255 255 / 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: 100%;
      max-width: 220px;
      max-height: 130px;

      & p {
        @include designSystem.textStyleHeadingSmall16;
        @include theme.apply(color, foreground-default);
      }

      .button {
        padding: 12px 24px;
        width: 100%;
        white-space: nowrap;
        display: flex;
        align-items: center;

        & svg {
          flex-shrink: 0;
        }

        &:focus {
          @include mixins.svgColor(brand-on-brand);
        }
      }
    }
  }
}
