@use 'const';
@use 'theme';
@use 'mixins';
@use 'designSystem';

.vcSpecs {
  display: flex;
  width: 100%;
  gap: 8px;

  span {
    white-space: nowrap;
  }
}
