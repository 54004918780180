@use 'responsive';
@use 'theme';
@use 'mixins';
@use 'designSystem';

.mostViewedCars {
  border-radius: 30px;
  padding: 49px 51px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  gap: 44px;

  @include theme.apply(background-color, neutral-white);

  .heading {
    @include designSystem.textStyleHeadingLarge30;
  }

  .vehicleGrid {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: minmax(495px, 1fr);

    @include responsive.ltDesktop {
      grid-auto-flow: column;
      grid-template-columns: auto;
      grid-auto-rows: minmax(333px, 1fr);
      overflow-x: scroll;
      overflow-y: hidden;
      padding-bottom: 12px;
      gap: 16px;

      @include mixins.scrollbars();

      & > * {
        min-width: 270px;
      }
    }
  }

  @include responsive.ltDesktop {
    padding: 24px 0;
    gap: 32px;
  }
}
