@use 'const';
@use 'responsive';
@use 'theme';
@use 'mixins';
@use 'designSystem';

$paginationButtonSize: 40px;

.paginationWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  > div {
    justify-content: center;
    width: inherit;
  }

  > p {
    @include theme.apply(color, foreground-muted);
    @include designSystem.textStyleBodySmall16;

    text-align: center;
  }
}

.pagination {
  @include theme.apply(background-color, foreground-lightest);
  @include theme.apply(color, action-light-on-action-light);

  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: const.$margin * 0.25;

  span {
    @include designSystem.textStyleHeadingSmall16;
    @include theme.apply(color, foreground-default);

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: $paginationButtonSize;
  }

  button {
    @include mixins.cleanButton;
    @include designSystem.textStyleHeadingSmall16;
    @include theme.apply(color, action-light-on-action-light);

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: $paginationButtonSize;
    height: $paginationButtonSize;
    border-radius: 8px;

    &.active {
      @include theme.apply(background-color, action-light-default);
      @include theme.apply(color, action-light-on-action-light);

      cursor: default;
    }

    .disabled:not(.active) {
      @include theme.apply(background-color, action-disabled);
      @include theme.apply(color, action-on-action);

      cursor: default;
    }

    &:hover:not(:disabled):not(.active) {
      @include theme.apply(background-color, action-darken);
      @include theme.apply(color, action-on-action);
    }

    &.back,
    &.next {
      @include theme.apply(background-color, action-default);
      @include theme.apply(color, action-on-action);

      &:hover:not(:disabled) {
        @include theme.apply(background-color, action-darken);
        @include theme.apply(color, action-on-action);
      }

      &:disabled {
        @include theme.apply(background-color, action-disabled);
        @include theme.apply(color, action-on-action);
      }
    }
  }

  @include responsive.mobile {
    gap: 0;
  }
}

[data-site='easyCarBuyers'] {
  .pagination {
    @include theme.apply(background-color, foreground-lightest);
    @include theme.apply(color, action-light-on-action-light);

    span {
      @include designSystem.textStyleHeadingSmall16;
      @include theme.apply(color, foreground-default);
    }

    button {
      @include theme.apply(color, action-light-on-action-light);
      @include theme.apply(color, foreground-default);

      &.active {
        @include theme.apply(background-color, foreground-disabled);
        @include theme.apply(color, foreground-default);
      }

      .disabled:not(.active) {
        @include theme.apply(background-color, action-disabled);
        @include theme.apply(color, action-on-action);
      }

      &:hover:not(:disabled):not(.active) {
        @include theme.apply(background-color, action-darken);
        @include theme.apply(color, action-on-action);
      }

      &.back,
      &.next {
        @include theme.apply(background-color, action-default);
        @include theme.apply(color, action-on-action);

        &:hover:not(:disabled) {
          @include theme.apply(background-color, action-darken);
          @include theme.apply(color, action-on-action);
        }

        &:disabled {
          @include theme.apply(background-color, action-disabled);
          @include theme.apply(color, action-on-action);
        }
      }
    }
  }
}
