@use 'const';
@use 'designSystem';
@use 'mixins';
@use 'responsive';
@use 'theme';

.dialogContent {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;

  .closeButton {
    @include mixins.cleanButton;
    @include theme.apply(color, action-default);

    position: absolute;
    top: -12px;
    right: -12px;
    z-index: 1;

    @include responsive.mobile {
      width: 48px;
      height: 48px;
    }
  }

  .imageContainer {
    display: flex;
    align-self: flex-start;

    > div {
      width: max-content;

      @include responsive.mobile {
        aspect-ratio: unset;
      }
    }
  }

  .content {
    @include designSystem.textStyleBodySmall14;
    @include theme.apply(color, foreground-default);

    display: flex;
    flex-direction: column;
    position: relative;
    gap: 16px;
    width: 100%;
    padding: 0;

    .image {
      border-radius: 100%;
      position: relative;
      width: 144px;
      height: 144px;
      overflow: hidden;
      object-fit: cover;
    }

    h1,
    .title {
      @include designSystem.textStyleHeadingMedium21;
      @include theme.apply(color, foreground-default);
    }

    .groups {
      display: flex;
      flex-direction: row;
      gap: 8px;

      .title {
        @include theme.apply(color, brand-default);
        @include designSystem.textStyleHeadingSmall14;

        padding: 3px 12px;
        border-radius: const.$margin * 1.5;
        border: solid 2px;

        @include theme.apply(border-color, brand-default);
      }
    }

    .paragraph {
      @include theme.apply(color, foreground-default);
      @include designSystem.textStyleBodySmall16;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .contact {
        @include theme.apply(color, action-default);
        @include designSystem.textStyleBodySmall16;

        text-decoration: none;
        display: grid;
        grid-template-columns: 24px 1fr;
        gap: 6px;
        align-items: center;
        width: max-content;
        max-width: 100%;

        span {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .contactButtons {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      &.buttonFullRow {
        grid-template-columns: 1fr 2fr 1fr;

        a,
        button {
          grid-column: 2;
        }
      }
    }

    .vcardLink {
      display: flex;
      position: relative;
      justify-content: center;
      width: 100%;

      &.noPhone {
        justify-content: flex-start;

        .vcard {
          min-height: 36px;
        }
      }

      .vcard {
        justify-content: center;
      }
    }
  }
}
