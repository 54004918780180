@use 'const';
@use 'designSystem';
@use 'responsive';
@use 'theme';

.contentList {
  display: flex;
  flex-direction: column;
  min-width: 0;
  gap: 40px;
  border-radius: const.$blockBorderRadius;
  overflow-x: hidden;
  padding: 48px 60px 60px;

  @include theme.apply(background-color, neutral-white);
  @include designSystem.defaultFontSizes;

  h2 {
    @include designSystem.textStyleHeadingLarge30;
    @include theme.apply(color, foreground-default);
  }

  .list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px 16px;

    @include responsive.ltDesktop {
      grid-template-columns: repeat(2, 1fr);
      gap: 16px 8px;
    }

    div {
      a {
        @include designSystem.textStyleBodySmall18;

        text-decoration: none;

        @include theme.apply(color, action-default);
      }
    }
  }

  @include responsive.ltDesktop {
    gap: 32px;
    padding: 48px 24px;
  }
}
