@use 'const';
@use 'responsive';
@use 'theme';
@use 'designSystem';
@use 'mixins';

.distinguishedIcons {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  align-items: flex-start;

  .icon {
    @include theme.apply(color, brand-default);
    @include designSystem.textStyleHeadingSmall14;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
}
