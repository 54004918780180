@use 'const';
@use 'theme';
@use 'designSystem';
@use 'mixins';

.field {
  position: relative;

  > .icon,
  > .percentIcon {
    @include mixins.svgColor(foreground-default);

    display: flex;
    position: absolute;
    top: 22px;
    bottom: 0;
    margin: auto;
    align-items: center;
  }

  > .icon {
    left: const.$formInputPaddingLeft + const.$formInputBorderWidth;

    + input {
      padding-left: const.$formInputPaddingLeft + const.$formInputBorderWidth + 16px;
    }
  }

  > .percentIcon {
    right: 18px;
  }

  input {
    width: 100%;
    height: const.$formInputHeight;
    padding: (32px + const.$formInputBorderWidth) (const.$inputPaddingLeft - const.$formInputBorderWidth * 2)
      const.$formInputPaddingTop;
    border: const.$formInputBorderWidth solid;
    border-radius: const.$formInputRadius;
    outline: none;
    flex: none;
    order: 0;
    flex-grow: 0;

    @include theme.apply(color, foreground-default);
    @include theme.apply(background-color, neutral-white);
    @include theme.apply(border-color, foreground-default);
    @include designSystem.textStyleBodyMedium22;

    &::placeholder {
      @include theme.apply(color, foreground-muted);
    }

    &:disabled {
      @include theme.apply(background-color, foreground-lightest);
      @include theme.apply(border-color, foreground-disabled);
      @include theme.apply(color, foreground-muted);
    }
  }
}
