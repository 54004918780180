@use 'const';
@use 'designSystem';
@use 'responsive';
@use 'theme';
@use 'mixins';

.favouritesMsg {
  @include theme.apply(color, foreground-on-foreground);
  @include designSystem.textStyleHeadingSmall16;
  @include mixins.svgColor(foreground-on-foreground);

  display: flex;
  align-items: center;
  gap: 8px;
  animation: fadeIn 0.3s ease-out;

  .viewFavourites {
    @include theme.apply(color, foreground-on-foreground);

    font-weight: 300;
    white-space: nowrap;
  }

  .undo {
    @include mixins.cleanButton;
    @include theme.apply(color, foreground-on-foreground);

    font-weight: 300;
    text-decoration-line: underline;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}