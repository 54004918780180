@use 'responsive';
@use 'designSystem';
@use 'theme';
@use 'mixins';

.staffsListPage {
  display: flex;
  flex-direction: column;
  gap: 72px;

  .list {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 32px 24px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 1fr;

    @include responsive.mobile {
      grid-template-columns: 1fr;
    }
  }
}
