@use 'const';
@use 'responsive';
@use 'designSystem';
@use 'theme';
@use 'mixins';

.rolesDesktop {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  gap: 24px;
  border-bottom: 1px solid;

  @include theme.apply(border-color, foreground-subtle);

  .role {
    @include mixins.cleanButton;
    @include theme.apply(color, action-default);
    @include designSystem.textStyleBodySmall16;

    border-bottom: 4px solid;
    border-color: transparent;
    padding-bottom: 16px;
    cursor: pointer;
    transition: 300ms ease all;

    &.active {
      border-bottom: 4px solid;

      @include theme.apply(border-color, foreground-default);
      @include theme.apply(color, foreground-default);
    }
  }
}

.rolesMobile {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  label {
    @include designSystem.textStyleHeadingSmall14;

    display: flex;
    align-items: center;
    gap: 12px;

    @include theme.apply(color, neutral-grey-10);

    .selectWrapper {
      padding: 10px 16px;
      border-radius: 30px;
      max-width: 250px;
      position: relative;
      display: flex;
      align-items: center;

      @include theme.apply(background-color, neutral-white);
      @include mixins.svgColor(action-default);

      select {
        @include theme.apply(color, action-default);
        @include designSystem.textStyleHeadingSmall14;

        border: none;
        appearance: none;
        background: none;
        padding-right: 18px + 8px; // SVG width + padding
      }

      svg {
        position: absolute;
        pointer-events: none;
        right: 16px;
      }
    }
  }

  @include responsive.ltDesktop {
    padding-top: const.$margin * 0.5;
  }
}
