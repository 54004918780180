@use 'responsive';

.content {
  display: flex;
  flex-direction: column;
  row-gap: 80px;

  @include responsive.mobile {
    row-gap: 24px;
  }

  a {
    text-decoration: none;
  }

  :global {
    .termsLink {
      text-decoration: none;
    }
  }
}
