@use 'theme';
@use 'designSystem';
@use 'responsive';
@use 'mixins';

.vehicleCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  z-index: 0;
  overflow: hidden;

  @include theme.apply('background', neutral-white);
  @include designSystem.effectShadowM();

  &.fitContainer {
    height: 100%;
  }

  &.cardShadowDefault {
    @include designSystem.effectStyleCardHover;
  }

  .carousel {
    aspect-ratio: 16 / 9;
    width: 100%;
  }

  .wrapper {
    display: flex;
    width: 100%;
    min-width: 0;
    flex-grow: 1;

    .detailsMostViewed {
      padding: 8px 24px 24px;
    }

    .details {
      position: relative;
      display: flex;
      width: inherit;
      padding: 24px;
      flex-direction: column;
      align-self: stretch;
      min-width: 0;
      gap: 8px;
      z-index: 0;

      @include theme.apply('color', foreground-default);
      @include designSystem.textStyleBodySmall16;

      .link {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 5;

        &:focus {
          outline: none;
        }
      }

      .heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 32px;

        .title {
          @include designSystem.textStyleHeadingMedium21;

          display: -webkit-box; //stylelint-disable-line
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;

          @include responsive.ltDesktop {
            @include designSystem.textStyleHeadingSmall18;
          }
        }

        .titleMostViewed {
          @include designSystem.textStyleHeadingSmall18;

          @include responsive.maxWidthBreakpoint(1100px) {
            .model {
              display: block;
            }
          }

          @include responsive.minWidthBreakpoint(1100px) {
            @include designSystem.textStyleHeadingSmall16;
          }
        }

        .shortlistSpace {
          visibility: hidden;
        }

        .shortlist {
          @include mixins.cleanButton;

          position: absolute;
          padding: 24px;
          top: 0;
          right: 0;
          display: inherit;
          z-index: 10;

          &:hover,
          &.shortlisted {
            @include theme.apply(color, brand-default);
          }

          @include responsive.ltDesktop {
            padding: 16px;
          }
        }
      }

      .priceWrapper {
        display: flex;
        gap: 6px;
        align-items: center;

        .governmentCharges {
          display: flex;
          gap: 4px;
          white-space: nowrap;

          .afterTrade {
            @include theme.apply(color, brand-default);
            @include designSystem.textStyleHeadingSmall16;

            line-height: 1.375;

            @include responsive.ltDesktop {
              @include designSystem.textStyleHeadingSmall14;

              line-height: 1.5;
            }
          }
        }

        .price {
          @include designSystem.textStyleHeadingMedium24;

          @include responsive.ltDesktop {
            @include designSystem.textStyleHeadingMedium21;
          }

          &.afterTrade {
            @include theme.apply(color, brand-default);
          }
        }
      }

      .repaymentInformation {
        display: flex;
        align-items: center;
        gap: 6px;

        .tooltip {
          position: relative;
          z-index: 10;
        }
      }

      .priceInfo {
        @include designSystem.textStyleBodySmall14;

        .priceTag {
          @include designSystem.textStyleHeadingSmall14;
        }
      }

      @include responsive.ltDesktop {
        @include designSystem.textStyleBodySmall14;

        padding: 16px;
      }

      .cta {
        margin-top: auto;
        padding-top: 16px;

        .button {
          z-index: 10;
          position: relative;
          width: 100%;
          height: 57px;

          @include designSystem.textStyleHeadingSmall18;

          @include responsive.ltDesktop {
            height: 44px;

            @include designSystem.textStyleHeadingSmall16;
          }
        }
      }
    }

    .stockNumber {
      @include designSystem.textStyleBodySmall12;
      @include theme.apply(color, foreground-muted);

      text-align: center;
    }
  }
}