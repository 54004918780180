@use 'const';
@use 'responsive';
@use 'theme';
@use 'designSystem';

.columns {
  display: flex;
  justify-content: space-between;

  @include responsive.ltDesktop {
    flex-direction: column;
    gap: const.$margin * 1.5;
  }

  .form {
    position: relative;
    min-width: 400px;

    @include responsive.ltXlDesktop {
      min-width: 360px;
    }

    @include responsive.ltLargeDesktop {
      min-width: 300px;
    }

    @include responsive.mobile {
      min-width: unset;
    }

    fieldset {
      border: none;
      display: flex;
      flex-direction: column;
      gap: 18px;
      margin: 0 0 18px;
      padding: 0;

      p {
        @include designSystem.textStyleBodyMedium22;
        @include theme.apply(color, foreground-default);

        margin-bottom: 14px;

        @include responsive.ltDesktop {
          @include designSystem.textStyleBodySmall18;

          font-weight: 600;
          margin-bottom: 0;
        }
      }

      .input {
        position: relative;
        width: 100%;

        label {
          position: absolute;
          padding-top: const.$formInputPaddingTop + const.$formInputBorderWidth;
          padding-left: const.$formInputPaddingLeft + const.$formInputBorderWidth;
          z-index: 1;

          @include theme.apply(color, foreground-default);
          @include designSystem.textStyleHeadingSmall12;

          line-height: 15px;

          &.disabled {
            @include theme.apply(color, foreground-disabled);
          }
        }
      }

      .select {
        width: 100%;
      }
    }

    p {
      &.yourRepayment {
        @include theme.apply(color, foreground-default);
        @include designSystem.textStyleHeadingMedium21;

        @include responsive.ltLargeDesktop {
          @include designSystem.textStyleHeadingSmall18;
        }

        @include responsive.mobile {
          @include designSystem.textStyleHeadingSmall18;
        }
      }
    }

    .repayment {
      @include theme.apply(color, foreground-default);
      @include designSystem.textStyleHeadingTitle54;

      margin-bottom: const.$margin * 0.5;

      span {
        @include designSystem.textStyleHeadingSmall18;
      }

      &.repaymentLarge {
        @include designSystem.textStyleHeadingLarge36;

        span {
          @include designSystem.textStyleHeadingSmall16;
        }
      }

      @include responsive.smallPhone {
        @include designSystem.textStyleHeadingLarge36;

        span {
          @include designSystem.textStyleHeadingSmall16;
        }
      }
    }

    .calculatorButtons {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin: const.$margin 0 0;

      > a {
        @include responsive.ltXlDesktop {
          @include designSystem.textStyleHeadingMedium21;

          line-height: 1;
          padding-left: const.$margin;
          padding-right: const.$margin;
        }

        @include responsive.ltLargeDesktop {
          @include designSystem.textStyleHeadingSmall18;

          line-height: 1;
          padding-left: 18px;
          padding-right: 18px;
          height: const.$buttonheightMedium;
        }

        @include responsive.smallPhone {
          @include designSystem.textStyleHeadingSmall16;

          line-height: 1;
        }
      }
    }
  }

  .totalInterest {
    @include designSystem.textStyleBodyMedium22;
    @include theme.apply(color, foreground-default);

    @include responsive.ltDesktop {
      display: none;
    }

    span {
      @include designSystem.textStyleHeadingMedium24;

      &.over {
        @include designSystem.textStyleBodyMedium22;

        font-weight: normal;
      }
    }
  }
}
