@use 'responsive';
@use 'theme';
@use 'designSystem';

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
  height: 100%;

  .ratingWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 18px;

    .name {
      @include designSystem.textStyleHeadingSmall18;
    }

    .rating {
      svg {
        width: auto;
        height: 30px;
        margin-right: 8px;

        @include responsive.tinyPhone {
          margin-right: 4px;
        }

        polygon {
          @include theme.apply(fill, brand-default);
        }

        path {
          @include theme.apply(stroke, brand-default);

          &:not([fill='none']) {
            @include theme.apply(stroke, 'none');
            @include theme.apply(fill, brand-default);
          }
        }
      }
    }
  }

  .paragraph {
    text-align: center;
    flex: 1;

    @include designSystem.textStyleBodyMedium22;

    button {
      padding: 0;
      border: none;
      background: none;

      @include designSystem.textStyleBodyMedium22;
    }
  }
}
