@use 'responsive';
@use 'theme';
@use 'designSystem';

.termsAndConditions {
  padding: 64px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @include responsive.mobile {
    padding: 48px 24px;
  }

  @include theme.apply(background-color, neutral-white);

  .heading {
    @include theme.apply(color, foreground-default);
    @include designSystem.textStyleHeadingMedium24;
  }

  .items {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    row-gap: 24px;

    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      height: 40px;
      min-width: 40px;

      @include theme.apply(color, action-default);
      @include designSystem.textStyleBodySmall16;
    }

    .text {
      .textHeading {
        min-height: 40px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        @include theme.apply(color, foreground-default);
        @include designSystem.textStyleHeadingSmall16;
      }

      .textContent {
        @include theme.apply(color, foreground-default);
        @include designSystem.textStyleBodySmall16;
      }
    }
  }

  .children {
    @include theme.apply(color, foreground-default);
    @include designSystem.textStyleBodySmall16;
  }
}
