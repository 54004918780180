@use 'const';
@use 'responsive';
@use 'theme';
@use 'mixins';
@use 'designSystem';

.boatCard {
  @include theme.apply(background-color, neutral-white);
  @include theme.apply(color, foreground-default);

  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  width: 100%;
  border-radius: const.$margin;
  overflow: hidden;
  z-index: 1;

  @include designSystem.effectStyleCardHover;

  @include responsive.ltLargeDesktop {
    min-height: unset;
  }

  img,
  svg {
    display: flex;
    object-fit: cover;
    width: 100%;
  }

  &.logoOnly {
    background: none;
    box-shadow: none;
    min-height: unset;
    align-items: center;

    @include responsive.mobile {
      gap: 24px;
    }

    .logo {
      position: relative;
      width: auto;
      max-height: 40px;
      max-width: 280px;
      object-fit: contain;
      object-position: center;
    }
  }

  .imageContainer {
    position: relative;

    > a > div {
      @include responsive.mobile {
        aspect-ratio: unset;
      }
    }

    .image {
      @include theme.apply(background-color, neutral-grey-90);

      aspect-ratio: 3 / 2;
      height: auto;
    }

    .logo {
      position: absolute;
      left: 24px;
      bottom: 16px;
      width: 200px;
      height: 28px;
      object-fit: contain;
      object-position: left center;
    }
  }

  .content {
    padding: 36px;
    display: flex;
    flex-direction: column;
    gap: const.$margin * 0.5;
    height: 100%;

    @include responsive.ltLargeDesktop {
      padding: 24px;
    }

    @include responsive.ltDesktop {
      padding: 36px;
    }

    @include responsive.ltMedium {
      padding: 24px;
    }

    @include responsive.mobile {
      padding: 36px;
    }

    @include responsive.smallPhone {
      padding: 24px;
    }

    h5 {
      @include theme.apply(color, foreground-default);
      @include designSystem.textStyleHeadingLarge30;

      @include responsive.ltDesktop {
        @include designSystem.textStyleHeadingMedium24;
      }
    }

    p {
      @include theme.apply(color, foreground-muted);
      @include designSystem.textStyleBodySmall18;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 12px;
      margin-top: auto;

      @include responsive.ltXlDesktop {
        flex-direction: column;
      }

      @include responsive.ltDesktop {
        flex-direction: row;
      }

      @include responsive.ltMedium {
        flex-direction: column;
      }

      @include responsive.mobile {
        flex-direction: row;
      }

      @include responsive.smallPhone {
        flex-direction: column;
      }

      .icon {
        margin-left: 6px;
        width: 24px;
        height: 24px;
      }

      a {
        @include responsive.mobile {
          height: 48px;
        }

        .screenReaderText {
          border: 0;
          clip: rect(1px, 1px, 1px, 1px);
          clip-path: inset(50%);
          height: 1px;
          margin: 0;
          padding: 0;
          overflow: hidden;
          position: absolute !important; // stylelint-disable-line
          width: 1px;
          white-space: nowrap;
        }
      }
    }

    .categories {
      display: inline-flex;
      gap: 4px;
      z-index: 10;

      a {
        @include designSystem.textStyleHeadingSmall14;

        text-transform: capitalize;
        text-decoration: none;

        @include theme.apply(color, action-default);

        @include responsive.ltDesktop {
          @include designSystem.textStyleBodySmall14;

          color: designSystem.$colorSecondaryMidGrey;
        }
      }
    }
  }
}
