@use 'const';
@use 'responsive';
@use 'theme';
@use 'designSystem';
@use 'mixins';

.contact {
  @include mixins.twoColumnsWithoutGapLayout;

  padding: 0;

  @include responsive.mobile {
    padding: 48px 0;
    gap: 48px;
  }

  > div {
    @include designSystem.defaultBannerSizes;
    @include designSystem.responsiveBannerSizes;
    @include theme.apply(background-color, neutral-white);

    padding: 64px;

    @include responsive.ltDesktop {
      &,
      &:not(.noPadding) {
        background: none;
        padding: 36px 0 0;
      }
    }

    &:not(.map) {
      border-radius: 30px 0 0 30px;
      padding: 64px 32px 64px 64px;

      @include responsive.ltLargeDesktop {
        padding: 48px 24px 48px 48px;
      }

      @include responsive.ltDesktop {
        padding: 0 12px 0 0;
      }

      @include responsive.mobile {
        padding: 0;
      }
    }

    &.map {
      border-radius: 0 30px 30px 0;
      padding: 64px 64px 64px 32px;

      @include responsive.ltLargeDesktop {
        padding: 48px 48px 48px 24px;
      }

      @include responsive.ltDesktop {
        padding: 0 0 0 12px;
      }

      @include responsive.mobile {
        padding: 0;
      }

      > a {
        height: 100%;

        img {
          border-radius: 8px;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    h3 {
      @include theme.apply(color, foreground-default);
      @include designSystem.textStyleHeadingLarge42;

      margin-bottom: const.$margin * 1.5;
    }

    h5 {
      @include theme.apply(color, foreground-default);
      @include designSystem.textStyleHeadingMedium21;
    }

    p,
    ul {
      @include theme.apply(color, action-default);
      @include designSystem.textStyleBodyLarge24;
    }

    ul {
      margin: 0;
    }

    > .columns {
      display: grid;
      grid-template-columns: 1fr;
      gap: 32px;
    }

    .phone,
    .email,
    .address,
    .socials,
    .speaker,
    .hours,
    .dealerLicence {
      @include designSystem.textStyleBodyLarge24;
      @include theme.apply(color, foreground-default);
      @include mixins.svgColor(foreground-default);

      display: flex;
      flex-direction: column;
      gap: 12px;

      > div {
        &:first-child {
          display: grid;
          grid-template-columns: 24px calc(100% - 28px);
          grid-gap: 8px;

          svg {
            width: 24px;
            height: auto;
            align-self: center;
          }

          .subHeading {
            @include designSystem.textStyleBodyLarge24;
          }
        }

        > div {
          p:not(:last-child) {
            margin-bottom: 8px;
          }
        }

        a,
        button {
          @include theme.apply(color, action-default);

          text-decoration: none;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &.address {
        > div {
          &:nth-child(2) {
            p {
              max-width: 535px;
            }
          }
        }
      }

      .contactParagraph {
        @include theme.apply(color, action-default);
      }
    }
  }
}
