@use 'const';
@use 'designSystem';
@use 'responsive';
@use 'theme';
@use 'mixins';

.contentFaqItem {
  border-radius: const.$blockBorderRadius;
  padding-bottom: 24px;

  @include theme.apply(background-color, neutral-white);
  @include theme.apply(color, foreground-default);

  .top {
    padding: 32px 32px 8px;
    background: none;
    border: 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    text-align: left;

    .heading {
      h2,
      h3,
      h4,
      h5,
      h6 {
        @include theme.apply(color, foreground-default);
        @include designSystem.textStyleHeadingMedium21;

        @include responsive.mobile {
          padding-right: 12px;
        }
      }
    }

    .chevron {
      @include mixins.svgColor(action-default);

      display: flex;
      align-items: center;

      svg {
        transition: 0.5s all ease;
      }
    }

    &.open {
      .chevron {
        svg {
          transform: rotateX(-180deg);
        }
      }
    }
  }

  .paragraph {
    padding: 8px 30px;

    h2,
    h3,
    h4,
    h5,
    p,
    ul,
    ol {
      @include designSystem.textStyleBodySmall18;

      margin-top: 0;
      margin-bottom: 8px;

      strong {
        @include theme.apply(font-weight, heading-weight);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      li {
        margin-bottom: 4px;
      }
    }
  }
}
